 <template>
	<el-dialog 
		top="1vh" title="打款" 
		append-to-body 
		custom-class="el-dialog-s" 
		width="600px" 
		:visible.sync="is_show_in_page"
	>
		<el-form label-width="100px">

			<el-form-item label="收款人">
				<el-input v-model="settle_user_info" ></el-input>
			</el-form-item>

			<el-form-item label="收款卡">
				<el-input v-model="settle_bank_card" ></el-input>
			</el-form-item>

			<el-form-item label="打款金额">
				<el-input v-model="this_settled_text" ></el-input>
			</el-form-item>

			<el-form-item label="平台付款卡">
				<el-select v-model="platform_bank_card_choosed" style="width:100%">
					<el-option v-for="(item,index) in platform_bank_card_list" :key="index" :label="item.card_num+' / '+item.bank_cname" :value="index"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="付款流水编号">
				<el-input v-model="platform_bank_ra_num" clearable></el-input>
			</el-form-item>

			<el-form-item label="打款备注">
				<el-input v-model="mark" clearable></el-input>
			</el-form-item>

			<el-form-item label="付款流水凭证">
				<div class="voucher" @click="voucher_choose()">
					<img v-if="voucher.src" :src="voucher.src" class="img">
					<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
				</div>
			</el-form-item>

		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</el-dialog>
</template>

<script>
	export default {
		props:{
			data:Object,
			is_show:Number
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//平台可用银行卡列表
				platform_bank_card_list:[],

				//平台可用银行卡选择结果
				platform_bank_card_choosed:'',

				//收款人
				settle_user_info:'',

				//收款卡
				settle_bank_card:'',

				//打款金额
				this_settled_text:'',

				//打款流水编号
				platform_bank_ra_num:'',

				//打款备注
				mark:"",

				//打款凭证
				voucher:{
					src: '', //图片地址
					key: '', //图片key
					is_choose: false,
					is_upl: false,
					bucket: 'tordrealtimeoutcashvoucher',
				},

				//提交锁
				sub_swh:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.is_show_in_page=false
				}else {
					this.is_show_in_page=true
					this.init();
				}
			},
		},
		methods: {

			//提交
			sub(){

				if(this.sub_swh){
					this.$my.other.msg({
						type:'warning',
						str:"提交中, 请稍等"
					});
					return;
				}

				if(!this.$my.check.is_num(this.platform_bank_card_choosed)){
					this.$my.other.msg({
						type:'warning',
						str:"请选择平台付款卡"
					});
					return;
				}

				if(!this.platform_bank_ra_num.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少付款流水编号"
					});
					return;
				}

				if(!this.voucher.is_choose){
					this.$my.other.msg({
						type:'warning',
						str:"请上传打款凭证"
					});
					return;
				}

				let that=this;
				let send=function(){
					//锁定
					that.sub_swh=true;
					that.$my.net.req({
						data:{
							mod:'truck_tord_real_time',
							ctr:'tord_settle_done_list_by_admin',
							payed_nums:that.data.payed_num,
							user_type:that.data.settle_target=='truck_owner'?1:2,
							platform_bank_cname:that.platform_bank_card_list[that.platform_bank_card_choosed].bank_cname,
							platform_card_num:that.platform_bank_card_list[that.platform_bank_card_choosed].card_num,
							platform_bank_ra_num:that.platform_bank_ra_num,
							platform_bank_ra_voucher:that.voucher.key,
							user_bank_cname:that.data.bankcard.bank_cname,
							user_card_num:that.data.bankcard.card_num,
							mark:that.mark,
							user_card_name:that.data.bankcard.card_user_name,
						},
						callback:(data)=>{

							//解锁
							that.sub_swh=false;

							//报告结果
							that.$my.other.msg({
								type:'success',
								str:"支付成功"
							});

							//关闭页面
							that.is_show_in_page=false;

							//通知
							that.$emit("cash_settle_end");
						}
					});
				};

				if(this.voucher.is_upl){
					send()
				}else{

					//上传
					this.$my.qiniu.upl({
						bucket: this.voucher.bucket,
						key: this.voucher.key, //xxxxxx?v=1 或者 null
						file_path: this.voucher.src,
						callback: (data) => {
							var key = data.key.split('__v__1')
							this.voucher.is_upl = true;
							this.voucher.key = key[0];
							send()
						}
					});
				}
			},

			//选择打款凭证
			voucher_choose(){

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						this.voucher.src = res.src;
						this.voucher.is_choose = true;
						this.voucher.is_upl = false;

						this.$my.qiniu.upl({
							bucket: this.voucher.bucket,
							key: this.voucher.key, //xxxxxx?v=1 或者 null
							file_path: this.voucher.src,
							callback: (data) => {
								var key = data.key.split('__v__1')
								this.$my.net.req({
									data:{
										mod:'finance',
										ctr:'ocr_out_cash',
										img_key:key[0],
									},callback:(data)=>{
									    this.mark = data.mark
										this.platform_bank_ra_num = data.replace_code
										this.platform_bank_card_list.forEach((item,index)=>{
											if(item.bank_cname == data.paymentBankName && item.card_num == data.paymentAccount){
												this.platform_bank_card_choosed = index
											}
										})
									}
								})
							}
						});
					}
				});
			},

			//页面初始化
			init(){
				//收款人
				this.settle_user_info=this.data.bankcard.card_user_name + '/' + this.data.bankcard.card_user_tel

				//收款卡
				this.settle_bank_card=`${this.data.bankcard.card_num} / ${this.data.bankcard.bank_cname} / ${this.data.bankcard.card_user_name}`

				//可用银行卡列表初始化
				this.platform_bank_card_list=[];
				
				//可用银行卡选择结果初始化
				this.platform_bank_card_choosed=''

				//打款金额字符串
				this.this_settled_text=this.data.this_settled+'元'

				//打款流水编号初始化
				this.platform_bank_ra_num=''

				//打款备注初始化
				this.mark=""

				//提交锁
				this.sub_swh=false;

				//打款凭证初始化
				this.voucher.src=''
				this.voucher.key=''
				this.voucher.is_choose=false
				this.voucher.is_upl=false

				//读取收款人的可用提现银行卡
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'platform_bankcard_list_admin',
						use_for_outcash:1,//是否用来出金(1:是,2:不是)
					},
					callback:(data)=>{
						
						//置入可用银行卡列表
						this.platform_bank_card_list=data.list
					}
				});
				this.$emit("loading_close");
			},
		}
	}
</script>
<style lang="scss" scoped>
	.el-dialog-s{
		z-index: 11;
	}
	.voucher{
		cursor: pointer;
		border: 1px solid #DCDFE6;
		border-radius:4px;
		text-align: center;
		height:250px;
		.img{
			max-width:calc(100% - 10px);
			max-height:calc(100% - 10px);
			padding:5px;
		}

		.icon{
			line-height: 250px;
			font-size: 80px;
			color: #ccc;
		}
	}

	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	
</style>