<template>
	<el-dialog title="更多搜索条件" top="1vh" width="92%" :visible.sync="is_show_in_page">
		
		<el-form class="form" label-width="100px">
			<div class="big_tit" style="margin:0">各种编号相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="支付编号" style="width:24%">
					<el-input v-model="form_in_page.payed_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付流水" style="width:24%">
					<el-input v-model="form_in_page.this_pay_shipper_ra_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="退款流水" style="width:24%">
					<el-input v-model="form_in_page.this_refund_shipper_ra_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单编号" style="width:24%">
					<el-input v-model="form_in_page.truck_tord_num" clearable></el-input>
				</el-form-item>
			</div>

			<div class="big_tit" >货主相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="手机号码" style="width:24%">
					<el-input v-model="form_in_page.shipper_tel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主姓名" style="width:24%">
					<el-input v-model="form_in_page.shipper_user_name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="公司名称" style="width:24%">
					<el-input v-model="form_in_page.shipper_company_name" clearable></el-input>
				</el-form-item>
			</div>

			<div class="big_tit" >收款人/司机/车辆</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人手机" style="width:24%">
					<el-input v-model="form_in_page.truck_owner_tel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="收款人姓名" style="width:24%">
					<el-input v-model="form_in_page.truck_owner_name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机手机" style="width:24%">
					<el-input v-model="form_in_page.driver_tel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机姓名" style="width:24%">
					<el-input v-model="form_in_page.driver_name" clearable></el-input>
				</el-form-item>
			</div>

			<div class="big_tit" >状态相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="计算方式" style="width:24%">
					<el-select v-model="form_in_page.calc_mode" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="服务费率" value="1"></el-option>
						<el-option label="固定金额" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付类型" style="width:24%">
					<el-select v-model="form_in_page.this_pay_type" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="现金" value="1"></el-option>
						<el-option label="燃油费" value="2"></el-option>
						<el-option label="过路费" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付状态" style="width:24%">
					<el-select v-model="form_in_page.pay_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="待审核" value="1"></el-option>
						<el-option label="完成" value="2"></el-option>
						<el-option label="打回" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="开票状态" style="width:24%">
					<el-select v-model="form_in_page.invoice_status" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未申请" value="1"></el-option>
						<el-option label="申请中" value="2"></el-option>
						<el-option label="已开出" value="3"></el-option>
						<el-option label="已驳回" value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="车队长结算" style="width:24%">
					<el-select v-model="form_in_page.settle_status_of_truck_owner" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未结算" value="1"></el-option>
						<el-option label="待打款" value="2"></el-option>
						<el-option label="已打款" value="3"></el-option>
						<el-option label="结算失败" value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机结算" style="width:24%">
					<el-select v-model="form_in_page.settle_status_of_driver" style="width: 100%;" clearable>
						<el-option label="不限" value=""></el-option>
						<el-option label="未结算" value="1"></el-option>
						<el-option label="待打款" value="2"></el-option>
						<el-option label="已打款" value="3"></el-option>
						<el-option label="结算失败" value="4"></el-option>
					</el-select>
				</el-form-item>
			</div>

			<div class="big_tit" >时效相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="支付发起" style="width:24%">
					<el-date-picker 
						v-model="form_in_page.creat_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="支付完成" style="width:24%">
					<el-date-picker 
						v-model="form_in_page.payed_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="撤销时间" style="width:24%">
					<el-date-picker 
						v-model="form_in_page.pay_refused_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="开票时间" style="width:24%">
					<el-date-picker 
						v-model="form_in_page.invoiced_time" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="车队长结算" style="width:24%">
					<el-date-picker 
						v-model="form_in_page.settled_time_of_truck_owner" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机结算" style="width:24%">
					<el-date-picker 
						v-model="form_in_page.settled_time_of_driver" 
						type="daterange" style="width: 100%;" 
						range-separator="至" 
						start-placeholder="开始日期" 
						end-placeholder="结束日期" 
					></el-date-picker>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">搜索</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	import {regionData,CodeToText} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	export default {
		props:{
			is_show:Number,//是否显示
			form:Object,//表单
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//表单
				form_in_page:{

					/* 支付记录相关搜索条件 */
					//各种编号
					payed_num:'',//支付编号
					truck_tord_num:'',//运单编号
					this_pay_shipper_ra_num:'',//本次支付对应的货主流水编号
					this_refund_shipper_ra_num:'',//如果退款的话退款对应的货主流水编号

					//货主相关
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称

					//收款人/司机/车辆
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名

					//各种状态
					calc_mode:'',//计算方式(1:服务费率模式(以货主需支付金额为基数,在每次支付时计算服务费),2:固定金额模式(货主需支付金额和结给承运人金额之间没有逻辑关系))
					this_pay_type:'',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status_of_truck_owner:'',//车队长结算状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					settle_status_of_driver:'',//司机结算状态(1:未申请,2:待打款,3:已打款,4:结算失败)

					//支付时效相关
					creat_time:null,//此笔支付创建时间
					payed_time:null,//货主支付完成时间
					pay_refused_time:null,//此笔支付撤销时间
					invoiced_time:null,//此笔支付开票时间
					settled_time_of_truck_owner:null,//此笔支付车队长结算完成时间
					settled_time_of_driver:null,//此笔支付司机结算完成时间
				},

				//网点列表
				outlets_list:[],

				//货物类型
				cargo_type:[],

				//货车列表
				truck_type:[],

				//城市列表
				regionData_in_page:regionData,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//显示界面
					this.is_show_in_page=true;

					//读取网点列表
					this.get_outlets_list();

					//读取发货参数
					this.get_creat_para();

					//置入外部表单数据
					for(var key in this.form){
						this.form_in_page[key]=this.form[key]
					}
					
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {

			//租用货车类型选择器
			ser_para_need_truck_types_change(event){
				this.form_in_page.need_truck_type=event[0];
				this.form_in_page.need_truck_type2=event[0];
			},

			//发货地省市县选择器
			ser_para_case_cities_change(event){
				this.form_in_page.case_prov=CodeToText[event[0]];
				this.form_in_page.case_city=CodeToText[event[1]];
				this.form_in_page.case_county=CodeToText[event[2]];
			},

			//到货地省市县选择器
			ser_para_aim_cities_change(event){
				this.form_in_page.aim_prov=CodeToText[event[0]];
				this.form_in_page.aim_city=CodeToText[event[1]];
				this.form_in_page.aim_county=CodeToText[event[2]];
			},
			
			//提交
			sub(){

				//通知
				this.$emit('sub',this.form_in_page)
			},

			//清空查询条件
			clear(){
				this.form_in_page={
					
					/* 支付记录相关搜索条件 */
					//各种编号
					payed_num:'',//支付编号
					truck_tord_num:'',//运单编号
					this_pay_shipper_ra_num:'',//本次支付对应的货主流水编号
					this_refund_shipper_ra_num:'',//如果退款的话退款对应的货主流水编号

					//货主相关
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称

					//收款人/司机/车辆
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名

					//各种状态
					calc_mode:'',//计算方式(1:服务费率模式(以货主需支付金额为基数,在每次支付时计算服务费),2:固定金额模式(货主需支付金额和结给承运人金额之间没有逻辑关系))
					this_pay_type:'',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status_of_truck_owner:'',//车队长结算状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					settle_status_of_driver:'',//司机结算状态(1:未申请,2:待打款,3:已打款,4:结算失败)

					//支付时效相关
					creat_time:null,//此笔支付创建时间
					payed_time:null,//货主支付完成时间
					pay_refused_time:null,//此笔支付撤销时间
					invoiced_time:null,//此笔支付开票时间
					settled_time_of_truck_owner:null,//此笔支付车队长结算完成时间
					settled_time_of_driver:null,//此笔支付司机结算完成时间
				}
			},

			//读取网点列表
			get_outlets_list(){
				
				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_list_admin',
						num:1000
					},
					callback:(data)=>{
						
						//渲染
						this.outlets_list=data.list
					}
				})
			},

			//获取创建参数
			get_creat_para(){

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(creat_para){
					this.truck_type=creat_para.truck_type
					this.cargo_type=creat_para.cargo_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.truck_type=truck_type
						this.cargo_type=data.cargo_type
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	.el_form_item{
		margin:15px 0 0 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		// padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.bottom_btns{
		text-align: center;
		width: 100%;
		padding-top: 15px;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		padding-bottom: 30px;
		overflow-y:auto;
		position: relative;
    	top: -20px;
	}
</style>