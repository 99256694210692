<template>
	<el-dialog title="导出搜索结果" :close-on-click-modal="false" width="300px" :visible.sync="is_show_in_page">
		<div style="text-align:center">总数量:{{max}} / 已读取:{{readed}}</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			rull:Object,//搜索条件
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,
				
				//单次读取数量
				num:100,

				//总数量
				max:0,

				//已读取
				readed:0,

				//上次最后一个id
				id_max:false,

				p:1,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.max=0
					this.readed=0
					this.id_max=false
					this.buf = []
					this.read()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			//生成csv
			make_csv(){
				var shunxu=[
					{key:"truck_tord_num",cname:"运单编号"},
					{key:"payed_num",cname:"支付流水号"},
					{key:"payed_time_text",cname:"支付时间"},
					{key:"case_addres",cname:"发货地址"},
					{key:"aim_addres",cname:"到货地址"},
					{key:"freight_total",cname:"总运费"},
					{key:"payed_total_of_all",cname:"已支付"},
                    {key:"service_charge_text",cname:"服务费"},
					{key:"shipper_company_name",cname:"货主公司名称"},
					{key:"truck_plate_num",cname:"车牌号"},
					{key:"driver_info_name",cname:"司机名称"},
					{key:"driver_info_tel",cname:"司机电话"},
					{key:"truck_info_name",cname:"收款人名称"},
					{key:"truck_info_tel",cname:"收款人电话"},
					{key:"this_driver_settled",cname:"结算给司机"},
					{key:"api_running_num",cname:"司机出款流水号"},
					{key:"this_truck_settled",cname:"结算给车队长"},
					{key:"api_running_num_truck_owner",cname:"车队长出款流水号"},
					{key:"truck_owner_card_user_name",cname:"收款人"},
					{key:"driver_card_user_name",cname:"司机收款人"},
					{key:"mark",cname:"备注"},
					{key:"mark2",cname:"mark2"},
					{key:"pay_status_text",cname:"支付状态"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<this.buf.length;i++){
					for(var a in shunxu){
						str+=`${this.buf[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "支付记录.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},

			//读取
			read(){
				var p = this.p
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin',
						is_get_shipper_info:true,
						is_get_shipper_company_info:true,
						is_get_truck_owner_info:true,
						is_get_driver_info:true,
						is_get_tord_info:true,
						...this.rull,
						num:this.num,
						p:p,
					},callback:(data)=>{
						this.p ++
						if(data.list.length ==0){
							this.p = 1
							this.make_csv()
							// this.read()
							return
						}
						//预处理
						for(let tord_item of data.list){
							//各种信息置入
                            tord_item.case_addres = tord_item.tord_info.case_prov + tord_item.tord_info.case_city + tord_item.tord_info.case_county
                            tord_item.aim_addres = tord_item.tord_info.aim_prov + tord_item.tord_info.aim_city + tord_item.tord_info.aim_county
                            tord_item.freight_total = tord_item.cost_info.freight_total
                            tord_item.payed_total_of_all = tord_item.this_payed
                            tord_item.shipper_company_name = tord_item.shipper_company_info.name
                            tord_item.driver_info_name = tord_item.driver_info.name
                            tord_item.driver_info_tel = tord_item.driver_info.tel
                            tord_item.truck_info_name = tord_item.truck_owner_info.name
                            tord_item.truck_info_tel = tord_item.truck_owner_info.tel
							tord_item.truck_plate_num = tord_item.tord_info.truck_plate_num
                            tord_item.this_settled=parseFloat(tord_item.this_settled_of_truck_owner)+parseFloat(tord_item.this_settled_of_driver)
                            tord_item.service_charge_text= Number((tord_item.this_payed-tord_item.this_settled)).toFixed(2)
							if (tord_item.driver_bank_list.num == 1) {
								tord_item.driver_bank_list_info = tord_item.driver_bank_list.list[0]
								tord_item.driver_card_user_name = tord_item.driver_bank_list_info.card_user_name
							}else if(tord_item.driver_bank_list.num == 0){
								tord_item.driver_card_user_name = '收款人信息为空'
							}else{
								tord_item.driver_card_user_name = '收款人绑定多张卡'
							}
							
							if (tord_item.truck_owner_bank_list.num == 1) {
								tord_item.truck_owner_bank_list_info = tord_item.truck_owner_bank_list.list[0]
							
								tord_item.truck_owner_card_user_name = tord_item.truck_owner_bank_list_info.card_user_name
							}else if(tord_item.driver_bank_list.num == 0){
								tord_item.truck_owner_card_user_name = '收款人信息为空'
								tord_item.truck_owner_card_user_name = '收款人绑定多张卡'
							}
                            // if(tord_item.truck_owner_num==tord_item.driver_num){
                            //     tord_item.this_driver_settled=parseFloat(tord_item.this_settled_of_truck_owner)+parseFloat(tord_item.this_settled_of_driver)
                            //     tord_item.this_truck_settled=0
                            // }else{
                                tord_item.this_truck_settled=tord_item.this_settled_of_truck_owner
                                tord_item.this_driver_settled=tord_item.this_settled_of_driver
                           // }
							tord_item.payed_time_text=`${this.$my.other.totime(tord_item.payed_time)}`;

							switch(tord_item.pay_status){
								case '1':tord_item.pay_status_text="未支付";break;
								case '2':tord_item.pay_status_text="支付中";break;
								case '3':
									tord_item.pay_status_text=`支付成功`;
									break;
								case '4':
									tord_item.pay_status_text=`支付失败`;
									break;
								case '5':
								tord_item.pay_status_text=`支付撤销`;
								break;
							}
					     }
                       //取出最后一个id
						if(data.list[data.list.length-1]){
                           this.id_max=data.list[data.list.length-1].id
						}

						//置入缓存
						this.buf=this.buf.concat(data.list)
						this.readed=this.buf.length
						
						//是否继续
						// if(this.readed<this.max){//没有达到最大数量则继续读取

						this.read();
						
						// }else{//达到最大数量则开始导出

						// 	this.make_csv()
						// }
                    }
				})
			},

			//启动
			start(){

				//读取总数量
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin',
						is_get_shipper_info:true,
						is_get_shipper_company_info:true,
						is_get_truck_owner_info:true,
						is_get_driver_info:true,
						is_get_tord_info:true,
						...this.rull,
                        num:10000,
					},
					callback:(data)=>{
						//如果总数量为0
						if(data.list.length==0){
							this.$my.other.msg({
								type:"warning",
								str:'该条件下数据为空'
							});
							return;
						
						//置入总数量
						}else this.max=data.list.length;

						//已读取数据
						this.buf=[];

						//开始读取
						this.read();
					}
				})
			},
		}
	}
</script>